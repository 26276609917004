import React, { useState } from 'react';
import {Modal, Button, Spinner} from 'react-bootstrap';

const FileUploadModal = ({ show, onHide, onFileUpload }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (selectedFile) {
            setIsSaving(true);
            await onFileUpload(selectedFile);
            setIsSaving(false);
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Datei hochladen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <input className="form-control" type="file" id="formFileMultiple" onChange={handleFileChange}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={handleUpload} disabled={isSaving}>
                    {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                    {isSaving ? ' Hochladen...' : 'Hochladen'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FileUploadModal;