import {CopyToClipboard} from "react-copy-to-clipboard/src";
import FileCard from "../components/FileCard";
import FileModal from "../components/FileModal";
import React, {useState} from "react";
import Toast from "../components/Toast";

const Assistant = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isMessageReceived, setIsMessageReceived] = useState(false);
    const [showDivider, setShowDivider] = useState(false);

    const [textareaValue, setTextareaValue] = useState('');
    const [assistantValue, setAssistantValue] = useState('');

    const [files, setFiles] = useState([]); // replace with actual file data
    const [selectedFile, setSelectedFile] = useState(null);

    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCardClick = (file) => {
        setSelectedFile(file);
    };

    const handleCloseModal = () => {
        setSelectedFile(null);
        // const requestOptions = {
        //     method: 'GET',
        //     headers: {'Content-Type': 'application/json'}
        // };
        // setFiles([]);
        // fetch('http://localhost:8081/api/get-available-files', requestOptions)
        //     .then(response => response.json())
        //     .then(data => {
        //         setFiles(data)
        //     })
        //     .catch(x => {
        //         console.log(x);
        //     }).finally(() => {
        // });
    };

    const sendMessage = () => {
        if(textareaValue === '') return;

        setIsLoading(true);
        setShowDivider(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ prompt: textareaValue })
        };
        fetch('https://cpi.hammerdigital.at/api/assistant-query', requestOptions)
            .then(response => {
                if (!response.ok) {
                    // Throw an error if the response status is not OK
                    resetBot();
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setAssistantValue(data.response_text);
                setFiles(data.used_files);
                setIsLoading(false);
                setIsMessageReceived(true);
            })
            .catch(error => {
                console.error('Error:', error);
                setErrorMessage('Verbindungsfehler'); // Set the error message
                setShowToast(true);
                resetBot();
            });
    };

    const resetBot = () => {
        setShowDivider(false);
        setIsLoading(false);
        setIsMessageReceived(false);
        setTextareaValue('');
    }

    const handleSaveFile = async (fileId, newContent) => {
        const formData = new FormData();
        formData.append('file', new Blob([newContent], {type: 'text/markdown'}), selectedFile.name);

        const response = await fetch('https://cpi.hammerdigital.at/api/overwrite-file/' + fileId, {
            method: 'POST',
            body: formData,
        });

        const new_file = await response.json();

        setFiles(files.map(file =>
            file.id === fileId ? { id: new_file.id, name: selectedFile.name } : file
        ));
    };

    return (
        <div>
            <div className="d-flex justify-content-center align-items-start mt-5">
                <div className="w-100" style={{maxWidth: '800px'}}>
                    <h5 className="text-center mb-3">domizil+ Rechnungswesen
                        Mitgliederbuchhaltung</h5> {/* Heading for the Textarea */}
                    <div className="form-group position-relative">
                      <textarea
                          className="form-control p-3"
                          id="messageTextarea"
                          rows="6"
                          placeholder="Stellen Sie eine Frage zur Mitgliederbuchhaltung..."
                          disabled={showDivider}
                          value={textareaValue}
                          onChange={(e) => setTextareaValue(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary mt-2" onClick={sendMessage}
                                disabled={showDivider || textareaValue === ''}>
                            Antwort suchen
                        </button>
                    </div>
                    {showDivider && <hr/>} {}

                    {isLoading && (
                        <div className="mt-3 position-relative">
                            <div className="progress" style={{height: '25px'}}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated"
                                     role="progressbar"
                                     style={{width: '100%'}}
                                     aria-valuenow="100"
                                     aria-valuemin="0"
                                     aria-valuemax="100">
                                    <strong>Der domizil+ Support BOT versucht für Sie die bestmögliche Antwort zu
                                        finden.</strong>
                                </div>
                            </div>
                        </div>
                    )}
                    {isMessageReceived && (
                        <div className="form-group position-relative mt-4">

                            <textarea
                                className="form-control no-focus-shadow p-3"
                                id="responseTextarea"
                                rows="10"
                                value={assistantValue}
                                readOnly
                            ></textarea>
                            <div className="d-flex justify-content-start">
                                <button className="btn btn-primary mt-2 mx-2" onClick={resetBot}>
                                    Neue Anfrage
                                </button>
                                <CopyToClipboard text={assistantValue}>
                                    <button className="btn btn-outline-primary mt-2">
                                        Text kopieren
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    )}
                    {showDivider && <hr/>} {}
                </div>
            </div>
            {isMessageReceived && (
                <div className="d-flex flex-column  align-items-center">
                    <h5>Referenzierte Dateien</h5>
                    <div className="d-flex flex-wrap">
                        {files.map(file => (
                            <FileCard key={file.id} file={file} onOpen={handleCardClick}/>
                        ))}
                    </div>

                    {selectedFile && (
                        <FileModal
                            show={!!selectedFile}
                            onHide={handleCloseModal}
                            file={selectedFile}
                            onSave={handleSaveFile}
                        />
                    )}
                </div>
            )};
            <Toast show={showToast} message={errorMessage} onClose={() => setShowToast(false)} />
        </div>
    );
}

export default Assistant;