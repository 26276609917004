import React, {useEffect, useState} from 'react';
import FileCard from "../components/FileCard";
import FileModal from "../components/FileModal";
import {FaCirclePlus} from "react-icons/fa6";
import FileUploadModal from "../components/FileUploadModal";
import {Spinner} from "react-bootstrap";

const KnowledgeBase = () => {
    const [files, setFiles] = useState([]); // replace with actual file data
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const handleCardClick = (file) => {
        setSelectedFile(file);
    };

    const handleCloseModal = () => {
        setSelectedFile(null);
        // const requestOptions = {
        //     method: 'GET',
        //     headers: {'Content-Type': 'application/json'}
        // };
        // setFiles([]);
        // setIsLoading(true);
        // fetch('https://cpi.hammerdigital.at/api/get-available-files', requestOptions)
        //     .then(response => response.json())
        //     .then(data => {
        //         setFiles(data)
        //     })
        //     .catch(x => {
        //         console.log(x);
        //     }).finally(() => {
        //         setIsLoading(false);
        //     });
    };

    const handleSaveFile = async (fileId, newContent) => {
        const formData = new FormData();
        formData.append('file', new Blob([newContent], {type: 'text/markdown'}), selectedFile.name);

        const response = await fetch('https://cpi.hammerdigital.at/api/overwrite-file/' + fileId, {
            method: 'POST',
            body: formData,
        });

        const new_file = await response.json();

        setFiles(files.map(file =>
            file.id === fileId ? { id: new_file.id, name: selectedFile.name } : file
        ));
    };

    const [showUploadModal, setShowUploadModal] = useState(false);

    const handleAddNewFile = () => {
        setShowUploadModal(true);
    };

    const handleFileUpload = async (file) => {
        if (!file) {
            alert('Please select a file first!');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://cpi.hammerdigital.at/api/upload-file', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                files.push(data);
                setFiles([]);
                setFiles(files);
            } else {
                console.error('Upload failed:', response);
            }
        } catch (error) {
            console.error('Error during upload:', error);
        }
    };


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        setIsLoading(true);
        fetch('https://cpi.hammerdigital.at/api/get-available-files', requestOptions)
            .then(response => response.json())
            .then(data => {
                setFiles(data)
            })
            .catch(x => {
                console.log(x);
            }).finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div>
            <div className="d-flex flex-column  align-items-center my-5">
                <h5>Alle Dateien</h5>
                <div className="d-flex flex-wrap justify-content-center align-center">
                    {isLoading ? (
                        <Spinner animation="border" role="status" className="mt-3">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : files.length !== 0 && (
                        files.map(file => (
                            <FileCard key={file.id} file={file} onOpen={handleCardClick}/>
                        ))
                    )}
                    {!isLoading && (
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                width: '100px',
                                height: '100px',
                                fontSize: '42px',
                                cursor: 'pointer',
                                backgroundColor: 'var($primary)'
                            }}
                            onClick={handleAddNewFile}>
                                <FaCirclePlus color="primary"/>
                        </div>
                    )}
                </div>
                {selectedFile && (
                    <FileModal
                        show={!!selectedFile}
                        onHide={handleCloseModal}
                        file={selectedFile}
                        onSave={handleSaveFile}
                    />
                )}

                <FileUploadModal
                    show={showUploadModal}
                    onHide={() => setShowUploadModal(false)}
                    onFileUpload={handleFileUpload}
                />
            </div>
        </div>
    );
};

export default KnowledgeBase;