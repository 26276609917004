import {useEffect, useState} from "react";
import MarkdownIt from "markdown-it";
import {Button, Modal, Spinner} from "react-bootstrap";
import MdEditor from "react-markdown-editor-lite";

const FileModal = ({ show, onHide, file, onSave }) => {
    const [content, setContent] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const mdParser = new MarkdownIt();


    useEffect(() => {
        const fetchFile = async () => {
            try {
                const response = await fetch(`https://cpi.hammerdigital.at/api/get-file/${file.id}`);
                if (!response.ok) {
                    setContent(':c');
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const text = await response.text();
                setContent(text);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        };

        fetchFile();
    }, [file.id]); // Dependency array with file.id

    const handleSave = () => {
        setIsSaving(true);
        onSave(file.id, content).then(x => {
            onHide();
        }).finally(() => {
            setIsSaving(false);
        });
    };


    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header className="mx-2" closeButton>
                <Modal.Title>Datei bearbeiten - {file.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MdEditor style={{ height: '500px' }} value={content.toString()} renderHTML={text => mdParser.render(text)} onChange={({ text }) => setContent(text)}  />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Schließen und zurück
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                    {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                    {isSaving ? ' Speichern...' : 'Speichern'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FileModal;