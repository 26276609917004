import {Card} from "react-bootstrap";
import {FaMarkdown} from "react-icons/fa6";

const FileCard = ({ file, onOpen }) => (
    <Card style={{ maxWidth: '16rem', textAlign: "center", justifyContent: "center" }} onClick={() => onOpen(file)} className="m-2">
        <Card.Body className="text-center">
            <FaMarkdown size="32px"></FaMarkdown>
            <Card.Title>{file.name}</Card.Title>
        </Card.Body>
    </Card>
);

export default FileCard;